<template>
	<div>
		<form @submit.prevent.stop="search">
			<div v-if="!nosearch" class="container-pesquisa mb-1">
				<div class="input-group">
					<div class="input-group-prepend">
						<div class="input-group-text">Pesquisar</div>
					</div>
					<input type="search" class="form-control" aria-label="Filtrar por texto" v-model="query" placeholder="Digite para filtrar por todos os campos" data-cy="Pesquisa">
					<div class="input-group-append">
						<button class="btn btn-primary px-4" type="submit" data-cy="Pesquisar"><SearchIcon/></button>
					</div>
				</div>
				<slot name="results-page"/>
			</div>
		</form>
		<div :class="scroll ? 'table-responsive' : 'table-responsive-md'">
			<table class="table table-hover mb-2" :class="{'condensed': condensed}" data-cy="Tabela" :data-asc="sortAsc">
				<thead>
					<tr>
						<th v-if="selectItem">
							<div style="display: flex">
								<input
									type="checkbox"
									v-model="isChecked"
									style="margin-right: 8px;"
									@change="selectAll(isChecked)"
								/>
								<div v-if="isChecked" class="container-acoes">
									<div
										v-if="permissaoPayment && globalAcoes"
										class="icon-acao icon-payment-bg icon-container"
										title="Baixa"
										@click="paymentItem('All')"
									>
										<CreditCardIcon size="24" alt="Pagamento"/>
									</div>
									<div
										v-if="permissaoCancel && globalAcoes"
										class="icon-acao icon-cancel-bg icon-container"
										title="Cancelar"
										@click="cancelItem('All')"
									>
										<XCircleIcon size="24" alt="Cancelar"/>
									</div>
									<div
										v-if="permissaoDelete && globalAcoes"
										class="icon-acao icon-delete-bg icon-container"
										title="Apagar"
										@click="deleteItem('All')"
									>
										<Trash2Icon size="24" alt="Apagar"/>
									</div>
								</div>
							</div>
						</th>
						<th v-if="acoes">Ações</th>
						<th
							scope="col" v-if="!sortable" v-for="(col, idx) in colunas" :key="idx"
						>
							{{ typeof col === 'string' ? col : (col.value || '') }}
							<div
								v-if="col?.alert"
								class="th-alert"
							>
								{{ col.alert }}
							</div>
						</th>
						<th
							scope="col" v-if="sortable" v-for="(col, idx) in colunas" :key="idx" @click="sortC(col, idx)" :data-active="sortBy==idx" :data-ordenar="typeof col === 'string' || col.sortable !== false"
							:class="getAlignColumn(idx)"
						>
							{{ typeof col === 'string' ? col : (col.value || '') }}
							<div
								v-if="col?.alert"
								class="th-alert"
							>
								{{ col.alert }}
							</div>
						</th>
						<th v-if="!noedit" data-editar>Editar</th>
						<th v-if="details" style="width: 5rem;">Detalhes</th>
					</tr>
				</thead>
				<tbody v-if="loading">
					<tr v-for="i in 3" :key="i">
						<td class="p-1" v-for="j in colunas.length + 1" :key="j">
							<tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 2rem; width:100%" theme="opacity"></tb-skeleton>
						</td>
					</tr>
				</tbody>
				<tbody v-else-if="sortedItems.length === 0">
					<tr>
						<td scope="row" class="text-center" :colspan="colunas.length + 1">{{errMsg || '(Vazio)'}}</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr v-for="(item, idx) in sortedItems" :key="idx" :style="getStyle(item)">
						<td v-if="selectItem && !item.notItemActions && !item.notItemSelect"><input type="checkbox" v-model="item.selected" @change="verifySelectedItens()"></td>
						<td v-else-if="item.notItemActions && acoes && selectItem || item.notItemSelect && acoes && selectItem"></td>
						<td v-if="acoes">
							<div class="container-acoes">
								<div
									v-if="permissaoPayment && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-payment-bg icon-container"
									title="Baixa"
									@click="paymentItem(item)"
								>
									<CreditCardIcon size="24" alt="Pagamento"/>
								</div>
								<div
									v-if="permissaoEdit && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-edit-bg icon-container"
									title="Editar"
									@click="editItem(item)"
								>
									<Edit3Icon size="24" alt="Editar"/>
								</div>
								<div
									v-if="permissaoCancel && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-cancel-bg icon-container"
									title="Cancelar"
									@click="cancelItem(item)"
								>
									<XCircleIcon size="24" alt="Cancelar"/>
								</div>
								<div
									v-if="permissaoDelete && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-delete-bg icon-container"
									title="Apagar"
									@click="deleteItem(item)"
								>
									<Trash2Icon size="24" alt="Apagar"/>
								</div>
								<div
									v-if="permissaoRecover && item.isCanceled"
									class="icon-acao icon-recover-bg icon-container"
									title="Recuperar"
									@click="recoverItem(item)"
								>
									<CheckCircleIcon size="24" alt="Recuperar"/>
								</div>
							</div>
						</td>
						<th scope="row">
							<slot :name="typeof colunas[0] === 'string' ? colunas[0] : (colunas[0].value || '')" :value="item.cols[0]" :item="item">
								<div style="padding: .5rem 0">
									{{ typeof item.cols[0] === 'string' ? item.cols[0] : (item.cols[0].value || '') }}
								</div>
							</slot>
						</th>
						<td v-for="coli in item.cols.length-1" :key="coli">
							<slot :name="typeof colunas[coli] === 'string' ? colunas[coli] : (colunas[coli].value || '')" :value="item.cols[coli]" :item="item">
								<div v-if="item.cols[coli]?.alert" class="d-flex text-center">
									<div class="linha-alert">
										{{ (item.cols[coli].value || item.cols[coli].value === 0) ? item.cols[coli].value : '' }}
									</div>
								<div class="ml-2">
									<InfoIcon class="icon-alert"/>
								</div>
								</div>
								<div v-else style="padding: .5rem 0"  :class="getAlignColumn(coli)">
									{{ (item.cols[coli] || item.cols[coli] === 0) ? item.cols[coli].value || item.cols[coli] : '' }}
								</div>
							</slot>
						</td>
						<td v-if="!noedit" data-editar class="align-middle">
							<router-link data-cy="editar" :to="item.href" class="btn btn-sm btn-outline-light text--black" title="Editar"><Edit3Icon size="20" alt="Editar"/></router-link>
						</td>
						<td v-if="details && !item.noDetails">
							<div
								class="icon-acao icon-details"
								title="Detalhes"
								@click="detailsItem(item)"
							>
								<FileTextIcon size="24" alt="Pagamento"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	const removeAccents = require("remove-accents");
	const argToString = str => str == null ? "-" : removeAccents(str.toString().toLowerCase());
	const isQueryInString = query => str => argToString(str).includes(query);
	const parseQuery = query => removeAccents(query.trim().toLowerCase());

	export default {
		name: "DataTable",
		props: {
			colunas: {
				type: Array,
				required: true
			},
			linhas: {
				type: Array,
				required: true
			},
			errMsg: {
				type: String,
				default: ""
			},
			loading: {
				type: Boolean,
				default: false
			},
			state: {
				type: Object,
				default: () => ({
					sortBy: 0,
					sortAsc: true,
					query: ""
				})
			},
			async: {
				type: Boolean,
				default: false
			},
			nosearch: {
				type: Boolean,
				default: false
			},
			scroll: {
				type: Boolean,
				default: false
			},
			noedit: {
				type: Boolean,
				default: false
			},
			condensed: {
				type: Boolean,
				default: false
			},
			acoes: {
				type: Boolean,
				default: false
			},
			permissaoEdit: {
				type: Boolean,
				default: false
			},
			permissaoCancel: {
				type: Boolean,
				default: false
			},
			permissaoDelete: {
				type: Boolean,
				default: false
			},
			permissaoPayment: {
				type: Boolean,
				default: false
			},
			permissaoRecover: {
				type: Boolean,
				default: false
			},
			selectItem: {
				type: Boolean,
				default: false
			},
			alignColumns: {
				type: Array
			},
			globalAcoes: {
				type: Boolean,
				default: false
			},
			details: {
				type: Boolean,
				default: false
			},
			sortable: {
				type: Boolean,
				default: true
			}
		},
		data () {
			const iState = this.state ? { ...this.state } : {
				sortBy: 0,
				sortAsc: true,
				query: ""
			};
			return {
				iState,
				q: parseQuery(iState.query),
				isChecked: false
			};
		},
		computed: {
			sortBy: {
				get () {
					return this.iState.sortBy;
				},
				set (v) {
					this.iState.sortBy = v;
				}
			},
			sortAsc: {
				get () {
					return this.iState.sortAsc ? 1 : -1;
				},
				set (v) {
					this.iState.sortAsc = v === 1;
				}
			},
			query: {
				get () {
					return this.iState.query;
				},
				set (v) {
					this.iState.query = v;
				}
			},
			sortedItems () {
				if (this.async)
					return this.linhas || [];
				let result = [];
				if (this.q === "")
					result = this.linhas || [];
				// escolhe linhas que alguma coluna tem a query
				else
					result = this.linhas && this.linhas.filter(l => l.cols.some(isQueryInString(this.q)));

				return result.sort((a, b) => !a.cols[this.sortBy].value ?
					(a.cols[this.sortBy] < b.cols[this.sortBy] ? this.sortAsc * (-1)
						: (a.cols[this.sortBy] > b.cols[this.sortBy]) ? this.sortAsc * 1
							: 0)
					: a.cols[this.sortBy].number ? (parseInt(a.cols[this.sortBy].value.replace(",", "")) < parseInt(b.cols[this.sortBy].value.replace(",", "")) ? this.sortAsc * (-1)
						: (parseInt(a.cols[this.sortBy].value.replace(",", "")) > parseInt(b.cols[this.sortBy].value.replace(",", ""))) ? this.sortAsc * 1
							: 0) : (a.cols[this.sortBy].value < b.cols[this.sortBy].value ? this.sortAsc * (-1)
						: (a.cols[this.sortBy].value > b.cols[this.sortBy].value) ? this.sortAsc * 1
							: 0));
			}
		},
		methods: {
			search () {
				this.q = parseQuery(this.query);
				this.emitState();
			},
			emitState () {
				this.$emit("state-change", { ...this.iState });
			},
			sortC (col, by) {
				if (typeof col === "string" || col.sortable !== false) {
					this.sortAsc = (this.sortBy == by) ? this.sortAsc * (-1) : 1;
					this.sortBy = by;
					this.emitState();

					if (this.async)
						this.$emit("clickSortTableBy", { sortBy: this.sortBy, order: this.sortAsc });
				}
			},
			updateState () {
				this.iState = { ...this.state };
				this.verifySelectedItens();
			},
			getStyle (item) {
				const style = {};
				if (item.color)
					style.color = item.color;
				if (item.backgroundColor)
					style.backgroundColor = item.backgroundColor;
				return style;
			},
			editItem (item) {
				this.$emit("clickEdit", item);
			},
			cancelItem (item) {
				this.$emit("clickCancel", item);
				this.isChecked = false;
			},
			deleteItem (item) {
				this.$emit("clickDelete", item);
				this.isChecked = false;
			},
			paymentItem (item) {
				this.$emit("clickPayment", item);
				this.isChecked = false;
			},
			detailsItem (item) {
				this.$emit("clickDetails", item);
				this.isChecked = false;
			},
			recoverItem (item) {
				this.$emit("clickRecover", item);
				this.isChecked = false;
			},
			selectAll (value) {
				this.linhas.forEach(item => {
					if (!item.notItemSelect)
						item.selected = value;
				});
				this.verifySelectedItens();
			},
			verifySelectedItens () {
				this.isChecked = this.linhas.some(item => item.selected === true);
				this.$emit("clickCheck", this.isChecked);
			},
			getAlignColumn (col) {
				if (!this.alignColumns) return "";
				const alignColumn = this.alignColumns.length && this.alignColumns[col];
				return alignColumn ? "text-".concat(alignColumn) : "";
			}
		},
		watch: {
			"state.query": "updateState",
			"state.sortBy": "updateState",
			"state.sortAsc": "updateState",
			linhas: "verifySelectedItens"
		}
	};
</script>

<style scoped>
	table thead {
		background-color: #f5f6fa;
	}

	table thead th {
		user-select: none;
	}

	table thead th[data-ordenar] {
		cursor: pointer;
		user-select: none;
	}

	table thead th[data-active] {
		color: var(--cal-table-col-active)
	}

	table thead th[data-ordenar]::after {
		content: ' ↕';
		color: #66666680;
	}

	table[data-asc="1"] thead th[data-active]::after {
		content: ' ↑';
		color: inherit;
	}

	table[data-asc="-1"] thead th[data-active]::after {
		content: ' ↓';
		color: inherit;
	}

	thead th {
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	[data-editar] {
		width: 8rem;
		text-align: center;
	}

	.container-pesquisa {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text--black {
		color: black;
	}

	.form-control {
		height: calc(1.5em + 0.75rem + 4px);
	}

	.table-responsive {
		max-height: 65vh;
		white-space: nowrap;
		overflow-y: auto;
		overflow-x: auto;
	}

	.table.condensed td,
	.table.condensed th {
		padding: 0.25rem 0.5rem;
	}

	@media (max-width: 500px) {
		.container-pesquisa.mb-1 {
			flex-direction: column;
			align-items: center;
		}

		.custom-select.ml-1 {
			margin: 0.25rem;
		}
	}

	.linha-alert {
		width: 70%;
		padding: .5rem 0;
		background-color: red;
		color: white;
		font-weight: 600;
	}

	.icon-alert {
		color: red;
		height: 100%;
	}

	.th-alert {
		height: 1.75rem;
		width: 1.75rem;
		margin-top: -3px;
		color: white;
		background: red;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		float: right;
	}

	.container-acoes {
		display: flex;
		margin-right: 5px;
	}

	.icon-acao {
		height: 35px;
		width: 35px;
		margin-right: 5px;
		border-radius: 3px;
	}

	.icon-delete-bg {
		display: flex;
		justify-content: center;
		background-color: red;
		padding: 2px;
	}

	.icon-cancel-bg {
		display: flex;
		justify-content: center;
		background-color: rgb(231, 134, 6);
		padding: 2px;
	}

	.icon-recover-bg {
		display: flex;
		justify-content: center;
		background-color: var(--cal-header-color);
		padding: 2px;
	}

	.icon-edit-bg {
		display: flex;
		justify-content: center;
		background-color: grey;
		padding: 2px;
	}

	.icon-payment-bg {
		display: flex;
		justify-content: center;
		background-color: green;
		padding: 2px;
	}

	.icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		cursor: pointer;
	}

	.icon-details {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-left: 8px;
		color: gray;
	}
</style>
